import React from "react";
import { createBrowserRouter, Link, RouterProvider } from "react-router-dom";
import Home from "./pages/home/Home";
import Contact from "./pages/Contact";
import Obituary from "./pages/Obituary";
import Self_driving_Car from "./pages/learn/slef-driving-car/Self_driving_Car";
import Blockchain_and_AI from "./pages/learn/emerging-tech/Emerging_Tech";
import About from "./pages/about/About";
import HealthTech from "./pages/learn/healthTech/HealthTech";
import AI from "./pages/learn/emerging-tech/ai/AI";
import Blockchain from "./pages/learn/emerging-tech/blockchain/Blockchain";
import VehicularPollution from "./pages/learn/slef-driving-car/VehicularPollution";
import PublicHealthHighlight from "./pages/learn/healthTech/PublicHealthHighlight";
import Index from "./pages/learn/book/Index";
import Abstraction from "./pages/learn/book/Abstraction";
import Accident from "./pages/learn/book/Accident";
import Conclusion from "./pages/learn/book/Conclusion";
import Introduction from "./pages/learn/book/Introduction";
import Preface from "./pages/learn/book/Preface";
import Future from "./pages/learn/book/Future";
import Problem_1 from "./pages/learn/book/Problem_1";
import Problem_2 from "./pages/learn/book/Problem_2";
import Problem_3 from "./pages/learn/book/Problem_3";
import Problem_4 from "./pages/learn/book/Problem_4";
import Tcs from "./pages/learn/tcs/Tcs";
import Cloud_intro from "./pages/learn/emerging-tech/cloud/Cloud_intro";
import Unsolved from "./pages/learn/unsolve/Unsolved";
import PvsNP from "./pages/learn/unsolve/PvsNP";
import Halting_problems from "./pages/learn/unsolve/Halting_problem";
import Integer_Facterization from "./pages/learn/unsolve/Integer_Facterization";
import Tcs_key_concepts from "./pages/learn/tcs/Tcs_key_concepts";
import Tcs_vs_AI from "./pages/learn/tcs/Tcs_vs_AI";
import Tcs_Intro from "./pages/learn/tcs/Tcs_Intro";

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },
  { path: "/contact", element: <Contact /> },
  { path: "/obituary", element: <Obituary /> },
  { path: "/self-driving-cars", element: <Self_driving_Car /> },
  { path: "/vehicular-pollution", element: <VehicularPollution /> },
  { path: "/bc-and-ai", element: <Blockchain_and_AI /> },
  { path: "/healthtech", element: <HealthTech /> },
  { path: "/publichealthhighlight", element: <PublicHealthHighlight /> },
  { path: "/ai", element: <AI /> },
  { path: "/blockchain", element: <Blockchain /> },
  { path: "/cloud-intro", element: <Cloud_intro /> },
  { path: "/tcs", element: <Tcs /> },
  { path: "/tcsvsai", element: <Tcs_vs_AI /> },
  { path: "/tcs-intro", element: <Tcs_Intro /> },
  { path: "/pvsnp", element: <PvsNP /> },
  { path: "/halting-problem", element: <Halting_problems /> },
  { path: "/integer-facterization", element: <Integer_Facterization /> },
  { path: "/unsolved-problems", element: <Unsolved /> },
  { path: "/tcs-concept", element: <Tcs_key_concepts /> },
 
  
 


{ path: "/bookindex", element: <Index /> },
{ path: "/abstraction", element: <Abstraction /> },
{ path: "/accident", element: <Accident /> },
{ path: "/conclusion", element: <Conclusion /> },
{ path: "/intro", element: <Introduction /> },
{ path: "/preface", element: <Preface /> },
{ path: "/future", element: <Future /> },
{ path: "/problem1", element: <Problem_1 /> },
{ path: "/problem2", element: <Problem_2 /> },
{ path: "/problem3", element: <Problem_3 /> },
{ path: "/problem4", element: <Problem_4 /> },

  
]);

function PageRoutes() {
  return <RouterProvider router={router} />;
}

export default PageRoutes;
